<template>
<div id="app">
  <loading :active.sync="loading"></loading>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card>
            <div slot="header">
              <div class="row">
              <div class="col-md-8">
                <strong>Tutorial Gera Preço</strong>
              </div>
              <div class="col-md-4" style="text-align:right;">
                
              </div>
              </div>
            </div>
          
          <div class="row" style="margin-top:20px;">
            <div class="col-md-12">
                <b-embed
                    type="iframe"
                    aspect="16by9"
                    src="https://www.prolucro.com.br/sistema-de-gestao/tutorial-gera-preco/"
                    allowfullscreen
                ></b-embed>
            </div>
          </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import Loading from 'vue-loading-overlay'

export default {
  data () {
    return {
      isActive: false,
      title: 'Tutorial',
      subtitle: '',
      note: {
        title: '',
        text: ''
      },
      notes: [],
      isHidden: true,
      loading: false
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.loading = true
    this.verificaSessao()
  },
  filters: {
    formataData: function (num) {
      if (typeof num !== 'undefined') {
        const d = new Date(num)
        //const ye = new Intl.DateTimeFormat('pt-BR').format(d)
        const ye = d.toLocaleString('pt-BR')
        return ye
      }
    },
    nl2br (str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
          return ''
      }
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>'
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
    }
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.recarregar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    recarregar: function () {
        this.loading = false
    }
  },
  components: {
    Loading
  }
}
</script>